<template>
  <span>
    <v-card color="grey" flat>
    <v-card-text class="pa-8 d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <h6>Hello, </h6>&nbsp <h6
          style="color: #008233; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 20ch;">{{
          userFullName
        }}</h6>
      </div>
      <heroicon @click="$router.push('/settings')" class="cursor-pointer mr-1" name="settingsOutlined"
                fill="#00000099"/>
    </v-card-text>
          <v-divider/>
          <v-card-text class="pa-8 d-flex align-center">
            <div style="opacity: 60%" class="d-flex align-center mt-2 mr-3">
            <heroicon name="walletOutlined" fill="black"/>
            </div>
            <div class="d-flex align-center">
               Balance:
              <span v-if="balance">
                <span class="green--text ml-2">{{ balance }} $</span>
              </span>
              <span class="ml-2" v-else>
                0.00 $
              </span>
            </div>
            <v-spacer/>
              <div style="cursor:pointer;" class="mt-2 d-flex align-center" @click="$router.push('/balance')">
                <heroicon name="addSquareOutlined" fill="black" opacity="60%"/>
              </div>
          </v-card-text>
  </v-card>
          <v-divider/>
    <v-card color="grey" flat>
     <v-card-actions class="pa-8 d-flex flex-wrap">
        <v-col cols="12" class="pt-0 pb-0 px-0 d-flex justify-center">
          <v-btn depressed color="primary" block large class="pa-0">
            <label for="excelFile" style="width: 100%; height: 100%">
              <div class="d-flex justify-center align-center cursor-pointer">
                <heroicon class="mr-2" name="cloudUploadOutlined" fill="white"/>
                <p class="font-weight-bold">Upload file</p>
              </div>
            </label>
          </v-btn>
          <input type="file"
                 id="excelFile"
                 ref="excelFile"
                 @change="handleFileExcel"
                 style="display: none"
                 accept=".xlsx, .xls"/>
        </v-col>
       <v-col>
   <a href="/static/files/Template.xlsx" download>
           <v-btn large text color="primary" block>
            download example
          </v-btn>
         </a>
       </v-col>
     </v-card-actions>
  </v-card>
        <v-card style="border: 1px solid #FF6B00 !important" class="mt-6" elevation="0" color="grey">
          <v-card-text class="pa-8">
            <div class="d-flex align-center">
              <heroicon class="mr-2" name="billOutlined" fill="#FF6B00"/>
              <span style="color:#FF6B00">Action required</span>
            </div>
            <div class="py-2 d-flex align-center justify-space-between cursor-pointer"
                 v-for="(action,index) in actionsReq"
                 :class="{ activeAction: action.type === $router.currentRoute.params.type }"
                 @click="activateActions(index)">
              <span class="ml-8">{{ action.name }}</span>
              {{ errorStat[action.statObject] }}
            </div>
          </v-card-text>
        </v-card>
    <v-card color="grey" elevation="0" class="mt-6">
      <v-card-text class="px-8 pt-4 pb-8 mb-10">
        <div class="d-flex flex-column align-start justify-center">
                    <div
                        class="d-flex align-center pt-4 justify-space-between"
                        style="font-size:16px;color:#000000;font-weight: 500;opacity: 60%; cursor: pointer; width: 100%"
                        v-for="(menu,index) in menus"
                        :key="index"
                        @click="activateMenu(index)"
                        :class="{ active: activeMenuIndex === index }"
                    >
                      <template v-if="menu.name === 'History'">
                        <div style="width: 100%" class="d-flex flex-column mt-2">
                          <v-divider/>
                           <div class="d-flex align-center mt-6">
                        <heroicon style="margin-top: 3px" class="mr-2 ml-1" name="historyOutlined"
                                  :fill="activeMenuIndex === index ? '#008233' : 'black'"/>
                      <p style="margin-left: -2px" class="text-14">{{ menu.name }}</p>
                      </div>
                        </div>
                      </template>

                      <div v-if="menu.name !== 'History'" class="d-flex align-center">
                        <heroicon class="mr-2 ml-1" :name="menu.icon"
                                  :fill="activeMenuIndex === index ? '#008233' : 'black'"/>
                      <p style="margin-left: -2px" class="text-14">{{ menu.name }}</p>
                      </div>
                      <div>
                          <span>{{ errorStat[menu.statObject] }}</span>
                      </div>
                    </div>
                    </div>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {mapActions, mapGetters} from "vuex";
import {Api} from "../api/http";

export default {
  name: "app-drawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    drawWidth: {
      type: [Number, String],
      default: '230',
    },
    value: Boolean,
  },
  data: () => ({
    mini: false,
    errorStat: {
      wrongProductArrived: 0,
      brokenGoods: 0,
      prohibitedForDelivery: 0,
      goodsDidNotArrived: 0,
      numberOfGoodsDoesNotRespond: 0,
      linkIsInvalid: 0,
      priceIsInvalid: 0,
      waitingWarehouse: 0,
      warehouseProcessing: 0,
      inTransit: 0,
      delivered: 0,
      history: 0,
    },
    actionsReq: [
      {
        name: "Wrong product arrived",
        type: "wrong-product-arrived",
        statObject: 'wrongProductArrived',
        length: 0,
      },
      {
        name: "Broken goods / damaged packaging",
        type: "broken-goods",
        statObject: 'brokenGoods',
        length: 0,
      },
      {
        name: "Sanctioned list / Prohibited from delivery",
        type: "sanctioned-list",
        statObject: 'prohibitedForDelivery',
        length: 0,
      },
      {
        name: "Goods did not arrive",
        type: "goods-did-not-arrive",
        statObject: 'goodsDidNotArrived',
        length: 0,
      },
      {
        name: "Number of goods does not correspond",
        type: "number-of-goods-does-not-correspond",
        statObject: 'numberOfGoodsDoesNotRespond',
        length: 0,
      },
      {
        name: "Link is invalid",
        type: "link-is-invalid",
        statObject: 'linkIsInvalid',
        length: 0,
      },
      {
        name: "Price is invalid",
        type: "price-is-invalid",
        statObject: 'priceIsInvalid',
        length: 0,
      },
    ],
    menus: [
      {
        name: "Exported files",
        route: "exported-files",
        icon: "fileTextOutlined",
        length: 0,
      },
      {
        name: "Waiting in warehouse",
        route: "waiting-in-warehouse",
        icon: "homeOutlined",
        statObject: 'waitingWarehouse',
        length: 0,
      }, {
        name: "Warehouse processing",
        route: "warehouse-processing",
        icon: "layersOutlined",
        statObject: 'warehouseProcessing',
        length: 0,
      }, {
        name: "In transit",
        route: "in-transit",
        icon: "routingOutlined",
        statObject: 'inTransit',
        length: 0,
      },
      {
        name: "Delivered",
        route: "delivered",
        icon: "mapPointOutlined",
        statObject: 'delivered',
        length: 0,
      },
      {
        name: "History",
        route: "history",
        icon: "mapPointOutlined",
        statObject: 'history',
        length: 0,
      },
    ],
    scrollSettings: {
      maxScrollbarLength: 160
    },
    existBalance: null,
    activeMenuIndex: null,
    activeActionIndex: null,
    model: {
      data: "",
      type: ""
    },
  }),
  watch: {
    showDrawer: {
      handler(val) {
        this.drawer = val
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['menu', 'userFullName', 'user']),
    active() {
      return views => !!views.find(v => ('/' + v.view) === this.$route.path)
    },
    drawer: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    balance() {
      if (this.existBalance !== null) {
        const b = Math.round(this.existBalance * 100) / 100
        const s = `${b}`.split('.')
        if (s.length < 2) return s[0] + '.00'
        else return s[0] + '.' + s[1].padEnd(2, '0')
      } else {
        return null
      }
    }
  },
  created() {
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.drawer = !this.drawer;
    });
    Api.parcel.errorStat().then(r => this.errorStat = r)
    Api.balance.get().then(r => this.existBalance = r)
  },
  methods: {
    ...mapActions(['setSelectedFile']),
    handleFileExcel(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.model.type = file.type.split("/")[1];
        this.model.data = window.btoa(reader.result);
        this.setSelectedFile(this.model.data)
        this.$router.push('/registry');
      });
      reader.readAsBinaryString(file);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    activateMenu(index) {
      this.activeMenuIndex = index;
      const menu = this.menus[index];
      this.$router.push({name: menu.route});
    },
    activateActions(index) {
      this.activeActionIndex = index;
      const action = this.actionsReq[index];
      window.location = '/wrong-product/' + action.type
    },
  },
};
</script>

<style lang="stylus">
/*@import '../../node_modules/vuetify/src/stylus/settings/_elevations.styl';*/


#appDrawer
  overflow: hidden


.drawer-menu--scroll
  height: calc(100vh - 48px)
  overflow: auto

.logout-button {
  margin: 0;
  width: 100%;
}

.text-title {
  //styleName: subtitle-1/subtitle-1, medium;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.0015em;
  text-align: left;
}

.menus:hover {
  background-color: #EEEEEE;
  cursor: pointer;
}

.active p {
  color: #008233;
}

.activeAction {
  color: #FF6B00;
}

</style>
