import {Http, PublicHttp} from "./http";

const BASE_URL = process.env.VUE_APP_PROTECTED_API_URL;
const PUBLIC_BASE_URL = process.env.VUE_APP_PUBLIC_API_URL;

const c = new Http(BASE_URL)
const pc = new PublicHttp(PUBLIC_BASE_URL)

export class AuthApi {

  get cli() {
    return c
  }

  auth = {
    current() {
      return c.get('/lk/auth/current')
    },

    password(
        {
          currentPassword,
          newPassword
        }
    ) {
      return c.post('/lk/auth/password', {currentPassword, newPassword})
    },
  }

  balance = {
    get() {
      return c.get('/lk/balance')
    },

    create(body) {
      return c.post('/lk/balance', body)
    },

    history() {
      return c.get('/lk/balance/history')
    },

    historyV2() {
      return c.get('/lk/balance/history/V2')
    },

    registry() {
      return c.get('/lk/balance/registry')
    },
  }

  dict = {
    countries() {
      return c.get('/lk/dict/countries')
    },

    office(cityCode) {
      return c.get(`/lk/dict/office?cityCode=${cityCode}`)
    },

    searchCity(countryCode, name) {
      return c.get(`/lk/dict/search/city?countryCode=${countryCode}&name=${name}`)
    },
  }

  excel = {
    convert(excelBase64String) {
      return c.post('/lk/excel/convert', excelBase64String)
    },
  }

  invoice = {
    get(from = undefined, until = undefined) {
      let url = '/lk/invoice'
      if (from) url += `?/from=${from}`
      if (until) url += (from ? '&' : '?') + `until=${until}`
      return c.get(url)
    },

    create({amount, description = null}) {
      return c.post('/lk/invoice', {amount, description})
    },
  }

  parcel = {
    create(body) {
      return c.post('/lk/parcel', body)
    },

    byError(error) {
      return c.get(`/lk/parcel/by/error?error=${error}`)
    },

    cities(regionCode) {
      return c.get(`/lk/parcel/cities?regionCode=${regionCode}`)
    },
    combine(body) {
      return c.post('/lk/parcel/combine', body)
    },

    couriers() {
      return c.get('/lk/parcel/couriers')
    },

    delivered() {
      return c.get('/lk/parcel/delivered')
    },

    errorStat() {
      return c.get('/lk/parcel/error/stat')
    },

    filter(filter) {
      return c.post('/lk/parcel/filter', filter)
    },

    filterDelivered(filter) {
      return c.post('/lk/parcel/filter/delivered', filter)
    },

    filterHistory(filter) {
      return c.post('/lk/parcel/filter/history', filter)
    },

    filterInTransit(filter) {
      return c.post('/lk/parcel/filter/in/transit', filter)
    },

    filterWarehouseProcessing(filter) {
      return c.post('/lk/parcel/filter/warehouse/processing', filter)
    },

    getById(id) {
      return c.get(`/lk/parcel/getById?id=${id}`)
    },

    getByTrack(track) {
      return c.get(`/lk/parcel/getByTrack?track=${track}`)
    },

    history() {
      return c.get(`/lk/parcel/history`)
    },

    inTransit() {
      return c.get(`/lk/parcel/in/transit`)
    },

    moveToHistory(parcelId) {
      return c.put(`/lk/parcel/move-to-history/${parcelId}`)
    },

    pvz(cityCode, weight) {
      return c.get(`/lk/parcel/pvz?cityCode=${cityCode}&weight=${weight}`)
    },

    regions(countryCode) {
      return c.get(`/lk/parcel/regions?countryCode=${countryCode}`)
    },

    return(parcels) {
      return c.post('/lk/parcel/return', parcels)
    },

    sendingCompany() {
      return c.get('/lk/parcel/sending/company')
    },

    skipProblem(error, parcels) {
      return c.post(`/lk/parcel/skip/problem?error=${error}`, parcels)
    },

    split(body) {
      return c.post('/lk/parcel/split', body)
    },

    statistic() {
      return c.get('/lk/parcel/statistic')
    },

    update(parcel) {
      return c.post('/lk/parcel/update', parcel)
    },

    updateExternalDelivery(deliveryService, externalTrack, id) {
      return c.post(`/lk/parcel/update?deliveryService=${deliveryService}&externalTrack=${externalTrack}&id=${id}`)
    },

    updateRecipient(body) {
      return c.post('/lk/parcel/update/recipient', body)
    },

    utilize(parcels) {
      return c.post('/lk/parcel/utilize', parcels)
    },

    waitingInWarehouse() {
      return c.get(`/lk/parcel/waiting/in/warehouse`)
    },

    warehouseProcessing() {
      return c.get(`/lk/parcel/warehouse/processing`)
    },

    withErrors(limit, offset) {
      return c.get(`/lk/parcel/with/errors?limit=${limit}&offset=${offset}`)
    },

    withoutErrors(limit, offset) {
      return c.get(`/lk/parcel/without/errors?limit=${limit}&offset=${offset}`)
    },
  }

  parcelRegistry = {
    countries() {
      return c.get('/lk/parcel/registry/countries')
    },

    excelByStatus(status) {
      return c.post(`/lk/parcel/registry/excel/by/status?status=${status}`)
    },

    excelPackage(error) {
      return c.post(`/lk/parcel/registry/excel/package?error=${error}`)
    },

    excelV2(from, to) {
      return c.post(`/lk/parcel/registry/excel/v2?from=${from}&to=${to}`)
    },

    excelWarehouseProcessing() {
      return c.post('/lk/parcel/registry/excel/warehouse/processing')
    },
  }

  stock = {
    get() {
      return c.get('/lk/stock')
    },
  }

  report = {
    create(body) {
      return c.post('/lk/report', body)
    },
    read() {
      return c.get('/lk/report')
    },
    downloadReport(id) {
      return c.get('/lk/report/download/' + id)
    },
  }

  user = {
    apiKey() {
      return c.get('/lk/user/api/key')
    },

    generateApiKey() {
      return c.post('/lk/user/generate')
    },

    updatePassword({password}) {
      return c.post('/lk/user/update/password', {password})
    },

    updateSettings(body) {
      return c.post('/lk/user/update/settings', body)
    }
  }
}

export class P_Api {
  auth = {
    login({email, password}) {
      return pc.post('/lk/public/auth/login', {email, password})
    },

    register({email, fullName, password, phoneNumber}) {
      return pc.post('/lk/public/auth/register', {email, fullName, password, phoneNumber})
    },
  }
}
