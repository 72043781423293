<template>
  <v-footer height="300px" class="bg " >
    <v-container style="width: 1200px">
      <v-row>
        <v-col cols="6" md="2" lg="2">
          <v-btn text class="mb-2" @click="$router.push('/cost-of-services')">Cost of services</v-btn>
          <v-btn text class="mb-2" @click="$router.push('/privacy-policy')">Privacy Policy</v-btn>
          <v-btn text class="mb-2" @click="$router.push('/public-offer')">Public offer</v-btn>
        </v-col>
        <v-col cols="6" md="2" lg="2">
        </v-col>
        <v-col cols="11" md="8" lg="8" class="py-2 d-flex justify-end">
          <div class="d-flex flex-column justify-space-around">
            <span class="caption mb-11" style="color:#008233;"> &copy; {{ new Date().getFullYear() }} Parcelpoint LLC</span>
            <p class="caption text-right" style="opacity: 60%;">Developed by <span class="ml-1 text-decoration-underline">PALEO</span></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "app-fab",

  data: () => ({
    fab: false
  }),
computed:{
  ...mapGetters(["windowWidth"])
},
  methods: {
    onScroll() {
      if (typeof window === "undefined") return;

      const top = window.pageYOffset || document.documentElement.offsetTop || 0;

      this.fab = top > 300;
    },
    toTop() {
      this.$router.push({ hash: "" });
      this.$vuetify.goTo(0);
    }
  }
};
</script>
