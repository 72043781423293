import {Http} from "./http";

const NEW_BASE_URL = process.env.VUE_APP_NEW_API_URL;

const c = new Http(NEW_BASE_URL);

export class NewApi {
    get cli() {
        return c;
    }

    exports = {
        exportReport(body) {
            return c.cli.post("/export/", body, {responseType: 'blob'});
        },

        exportAllReports() {
            return c.cli.post("/export/all", {}, {responseType: 'blob'});
        },
    }
}