import http from "../plugins/http";
import store from "../store";

export default [
    {
        path: "*",
        meta: {
            public: true
        },
        redirect: {
            path: "/404"
        }
    },
    {
        path: "/404",
        meta: {
            public: true
        },
        name: "NotFound",
        component: () => import(`@/views/error/NotFound.vue`)
    },
    {
        path: "/403",
        meta: {public: true},
        name: "AccessDenied",
        component: () => import(`@/views/error/Deny.vue`)
    },
    {
        path: "/500",
        meta: {public: true},
        name: "ServerError",
        component: () => import(`@/views/error/Error.vue`)
    },
    {
        path: "/login",
        meta: {login: true},
        name: "Login",
        component: () => import(`@/views/Login.vue`)
    },
    {
        path: "/",
        meta: {},
        name: "Root",
        redirect: {
            name: "waiting-in-warehouse"
        }
    },
    {
        path: "/signup",
        meta: {login: true},
        name: "Signup",
        component: () => import(`@/views/Register.vue`)
    },
    {
        path: "/balance",
        meta: {userInfo: true},
        name: "balance",
        component: () => import(`@/views/Balance.vue`)
    },
    {
        path: "/cost-of-services",
        meta: {fullPage: true},
        name: "costOfServices",
        component: () => import(`@/views/CostOfServices.vue`)
    },
    {
        path: "/privacy-policy",
        meta: {fullPage: true},
        name: "privacyPolicy",
        component: () => import(`@/views/PrivacyPolicy.vue`)
    },
    {
        path: "/under-development",
        meta: {fullPage: true},
        name: "underDevelopment",
        component: () => import(`@/views/UnderDevelopment.vue`)
    },
    {
        path: "/public-offer",
        meta: {fullPage: true},
        name: "publicOffer",
        component: () => import(`@/views/PublicOffer.vue`)
    },
    {
        path: "/referral-program",
        meta: {userInfo: true},
        name: "referral-program",
        component: () => import(`@/views/ReferralProgram.vue`)
    },
    {
        path: "/waiting-in-warehouse",
        meta: {userInfo: true},
        name: "waiting-in-warehouse",
        component: () => import(`@/views/Business/pages/WaitingInWarehouse.vue`)
    },
    {
        path: "/history",
        meta: {userInfo: true},
        name: "history",
        component: () => import(`@/views/Business/pages/History.vue`)
    },
    {
        path: "/warehouse-processing",
        meta: {userInfo: true},
        name: "warehouse-processing",
        component: () => import(`@/views/Business/pages/WarehouseProcessing.vue`)
    },
    {
        path: "/in-transit",
        meta: {userInfo: true},
        name: "in-transit",
        component: () => import(`@/views/Business/pages/InTransit.vue`)
    },
    {
        path: "/delivered",
        meta: {userInfo: true},
        name: "delivered",
        component: () => import(`@/views/Business/pages/Delivered.vue`)
    },
    {
        path: "/registry",
        meta: {fullPage: true},
        name: "business-registry",
        component: () => import(`@/views/Business/pages/RegistryItems.vue`)
    },
    {
        path: "/wrong-product/:type",
        meta: {userInfo: true},
        name: "wrong-product",
        component: () => import(`@/views/Business/pages/WrongProductArrived.vue`)
    },
    {
        path: "/settings",
        meta: {userInfo: true},
        name: "settings",
        component: () => import(`@/views/Settings.vue`)
    },
    {
        path: "/exported-files",
        meta: {userInfo: true},
        name: "exported-files",
        component: () => import(`@/views/ExportedFiles.vue`)
    },
    {
        path: "/track",
        meta: {userInfo: true},
        name: "track-package",
        component: () => import(`@/views/TrackParcel.vue`)
    },
];
