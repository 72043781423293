import Vue from 'vue';
import Vuex from 'vuex';
import pages from './api/pages'
import router from "@/router";
import {Api} from "./api/http";
import {PublicApi} from "./api/http";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        windowWidth: 0,
        globalErrorMessage: {},
        currentParcel: null,
        changeParcel: [],
        status: '',
        selectedFile: localStorage.getItem('selectedFile') || '',
        user: {
            roles: [],
        },
        hasAny: perm => false,
        loading: false,
        refreshPageDataCallback: null,
        defaultRefreshPageDataCallback: () => {
            window.location.reload();
            history.go(0);
        },
        errorMessages: [],
        menu: []
    },
    mutations: {
        set_current_parcel(state, payload) {
            state.currentParcel = payload;
        },
        set_parcel_to_change(state, payload) {
            state.changeParcel = payload;
        },
        window_width(state, payload) {
            state.windowWidth = payload;
        },
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state) {
            state.status = 'success';
        },
        set_selected_file(state, payload) {
            state.selectedFile = payload;
            localStorage.setItem('selectedFile', payload);
        },
        set_user(state, payload) {
            state.user = payload;
            state.hasAny = permission => {
                let r = false;
                if (state.user && state.user.roles) {
                    r = state.user.roles.filter(role => role.active && role.permissions.filter(p => p.name.value === permission || p.name.value === 'ADMIN').length > 0).length > 0
                }
                console.debug('hasAny', permission, r)
                return r;
            }
        },
        auth_error(state) {
            state.status = 'error';
        },
        logout(state) {
            state.status = '';
            state.user = {};
        },
        set_loading(state, payload) {
            state.loading = payload;
        },
        set_global_error_message(state, val) {
            state.globalErrorMessage = val
        },
        set_menu(state, val) {
            state.menu = val
        },
        add_error_messages(state, message) {
            state.errorMessages.push({message: message, color: 'rgba(35,40,51,0.93)'})
        },
        add_success_messages(state, message) {
            state.errorMessages.push({message: message, color: 'rgba(76,175,80,0.93)'})
            if (!!state.errorMessages && Array.isArray(state.errorMessages) && state.errorMessages.length > 0) {
                setTimeout(() => {
                    state.errorMessages = state.errorMessages.slice(0, -1)
                }, 3000);
            }
        }
    },
    actions: {
        setSelectedFile(context, val) {
            context.commit('set_selected_file', val);
        },
        setCurrentParcel(context, val) {
            context.commit('set_current_parcel', val);
        },
        setParcelToChange(context, val) {
            context.commit('set_parcel_to_change', val);
        },
        setWindowWidth(context, val) {
            context.commit('window_width', val);
        },

        setGlobalErrorMessage(context, message) {
            // if (message === undefined) {
            //     context.commit('set_global_error_message', {
            //         message: 'Упс... Что-то пошло не так',
            //         error: null
            //     });
            // } else if (message instanceof Error) {
            //     console.error(message)
            //     context.commit('set_global_error_message', {
            //         message: 'Упс... Что-то пошло не так',
            //         error: message
            //     });
            // } else {
            //     context.commit('set_global_error_message', {
            //         message: message,
            //         error: null
            //     });
            // }
        },

        setLoading: function (context, value) {
            context.commit('set_loading', value);
        },

        init(context) {
            const token = localStorage.getItem('token');
            if (token) {
                Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                this.commit('auth_success');

                Api.auth.current()
                    .then(value => this.commit('set_user', value))
            } else {
                this.commit('logout')
                router.push("/login");
            }
        },

        login({commit, dispatch}, loginForm) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                PublicApi.auth.login(loginForm)
                    .then(r => {
                        Api.cli.login(r.token)
                        dispatch('init');
                        resolve(r);
                    }, e => {
                        this.commit('auth_error');
                        console.warn(e)
                        reject(e.message);
                    })
            });
        },
        logout({commit}) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                commit('logout');
                Api.cli.logout()
                delete Vue.prototype.$http.defaults.headers.common['Authorization'];
                resolve();
            });
        },
        addErrorMessages(context, message) {
            context.commit('add_error_messages', message);
        },
        addSuccessMessages(context, message) {
            context.commit('add_success_messages', message);
        },
    },
    getters: {
        windowWidth: state => state.windowWidth,
        modalWidth: state => {
            let dw = (700 / state.windowWidth) * 90;
            if (state.windowWidth > 1280) return '30vw';
            else if (state.windowWidth < 700) return '90vw';
            else return Math.round(dw) + 'vw';
        },
        authStatus: state => state.status,
        currentParcel: state => state.currentParcel,
        changeParcel: state => state.changeParcel,
        user: state => state.user,
        username: state => state.user.email,
        userFullName: state => state.user.fullName,
        loading: state => state.loading,
        selectedFile: state => state.selectedFile,
        globalErrorMessage: state => state.globalErrorMessage,
        globalErrorDialog: state => state.globalErrorMessage && state.globalErrorMessage.message,
        hasAny: state => state.hasAny,
        allRoles: state => state.user.roles || [],
        activeRole: state => (state.user.roles || []).filter(role => role.active).values().next().value,
        errorMessages: state => state.errorMessages,
        menu: state => state.menu
    }
});
