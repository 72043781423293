<template>
  <div id="appRoot" v-on:resize="getWidth">
    <template v-if="$route.meta.userInfo">
      <v-app id="inspire" class="app">
        <app-toolbar :drawer="drawer" @drawer="(status) => drawer = status"/>
        <v-main class="my-8">
          <v-row class="pa-0 page-wrapper">
            <v-col v-if="windowWidth > 1050" cols="4" class="pa-0 pt-2">
              <app-drawer/>
            </v-col>
            <v-col :style="windowWidth > 1060 && 'paddingLeft:16px !important'" cols="8" class="pa-0">
              <router-view/>
            </v-col>
          </v-row>
          <app-fab/>
        </v-main>
        <v-navigation-drawer style="padding-top: 100px" :width="windowWidth < 960 ? '70vw' : '50vw'"
                             v-if="drawer && windowWidth < 1051" class="drawer" v-model="drawer" app>
          <app-toolbar @drawer="(status) => drawer = status"/>
          <app-drawer/>
        </v-navigation-drawer>
      </v-app>
    </template>
    <template v-else-if="$route.meta.fullPage">
      <v-app id="inspire" class="app">
        <app-toolbar :drawer="drawer" @drawer="(status) => drawer = status"/>
        <v-main class="my-8">
          <v-row class="pa-0 page-wrapper">
            <v-col :style="windowWidth > 1051 && 'paddingLeft:16px !important'" cols="12" class="pa-0 page-wrapper">
              <router-view/>
            </v-col>
          </v-row>
          <app-fab/>
        </v-main>
        <v-navigation-drawer style="padding-top: 100px" :width="windowWidth < 960 ? '70vw' : '50vw'"
                             v-if="drawer && windowWidth < 1051" class="drawer" v-model="drawer" app>
          <app-toolbar @drawer="(status) => drawer = status"/>
          <app-drawer/>
        </v-navigation-drawer>
      </v-app>
    </template>
    <template v-else-if="$route.meta.login">
      <v-app id="inspire" class="app" style="background-color: white">
        <v-app-bar outlined absolute color="white" style="background-color: white;" class="d-flex justify-center py-2"
                   elevation="0">
          <div class="d-flex" style="display: flex; align-items: center; border-bottom: 1px solid #ccc; width: 100vw">
            <v-spacer v-if="currentRouteName === 'Signup' || currentRouteName === 'Login'"/>
            <div class="cursor-pointer" @click="$router.push('/')">
              <v-img src="/static/images/logo.svg" height="74px" contain/>
            </div>
            <v-spacer/>
          </div>
        </v-app-bar>

        <v-row class="pa-0 page-wrapper">
          <v-col :style="windowWidth > 1024 && 'paddingLeft:16px !important'" cols="12" class="pa-0">
            <router-view/>
          </v-col>
        </v-row>
      </v-app>
    </template>

    <template v-else>
      <div class="client-header" style="padding-top: 20px">
        <v-app-bar flat style="background: #F5F5F5;" height="100">
          <div class="page-wrapper land-head d-flex" style="display: flex; align-items: center; width: 100vw;">
            <v-spacer v-if="currentRouteName === 'Signup' || currentRouteName === 'Login'"/>
            <div class="cursor-pointer" @click="$router.push('/')">
              <v-img src="/static/images/logo.svg" height="74px" contain/>
            </div>
            <v-spacer/>
            <v-btn @click="$router.push('login')" text
                   v-if="currentRouteName !== 'Signup' && currentRouteName !== 'Login'">
              <span style="font-family: 'DM Sans'">Log in</span>
            </v-btn>
          </div>
        </v-app-bar>
      </div>
      <v-app class="primary" style="background-color: #f5f5f5">
        <v-row class="pa-0">
          <v-col cols="12" class="pa-0 page-wrapper">
            <router-view/>
          </v-col>
        </v-row>
        <app-fab style="background: #F5F5F5" v-if="currentRouteName !== 'Signup' && currentRouteName !== 'Login'"/>
      </v-app>
    </template>
    <error-dialog :global-error-dialog="globalErrorDialog"
                  :global-error-message="globalErrorMessage"
                  @click="setGlobalErrorMessage($event)"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import AppFab from "@/components/AppFab";
import AppEvents from "./event";
import {Api} from "./api/http";

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab
  },
  data: () => ({
    width: 0,
    expanded: true,
    rightDrawer: false,
    showDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    },
    drawer: false,
    dialog: false,
    trackNum: ''
  }),
  computed: {
    ...mapGetters(['loading', 'windowWidth', 'modalWidth', 'globalErrorMessage', 'globalErrorDialog', 'errorMessages']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    }
  },
  methods: {
    ...mapActions(['init', 'setWindowWidth', 'setGlobalErrorMessage']),
    setTrackPack() {
      this.dialog = false
      this.$router.push({name: 'track-package'})
    },
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },
  },
  created() {
    this.init();
    this.getWidth();

    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'shortcut icon';
    link.href = '/favicon.png';
    document.getElementsByTagName('head')[0].appendChild(link);

    this.width = document.body.clientWidth;
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;

    Api.cli.set401Interceptor(() => {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    })

    Api.cli.setGlobalErrorHandler(e => this.setGlobalErrorMessage(e.d.messages))

    this.$http.interceptors.response.use(
        response => {
          return response;
        },
        err => {
          return new Promise((resolve, reject) => {
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            } else {
              reject(err);
            }
          });
        }
    );
  }
};
</script>

<style lang="stylus" scoped>
.drawer {
  z-index 1;
}

.client-header {
  background-color: #f5f5f5 !important
}

a:active, a:focus {
  outline: none;
}

textField, textarea {
  outline: none;
}

textField:active, textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.bg {
  background-color: #fafafa;
}

//.container {
//  padding: 15px !important;
//}

textarea:focus, input:focus {
  outline: none;
}

.land-head {
  height 100%
  border-radius 16px
  background white
  max-width 1175px
  padding 40px
}
</style>
