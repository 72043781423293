import axios from 'axios';

const JWT_NAME = 'token'

function create(http, baseUrl) {
  const h = {
    Accept: 'application/json',
    'Content-Type': 'application/json;utf-8',
  }

  const jwt = localStorage.getItem(JWT_NAME);

  if (jwt) {
    h.Authorization = `Bearer ${jwt}`
  }

  if (http.interceptorId > -1) {
    http.ax.interceptors.response.eject(http.interceptorId)
  }

  http.ax = axios.create({
    baseURL: baseUrl,
    headers: h,
    timeout: 180000
  })

  if (http.interceptor) {
    const interceptor = http.interceptor
    http.interceptorId = http.ax.interceptors.response
        .use(
            r => r,
            (error) => {
              if (error.response?.status === 401) {
                http.logout()
                interceptor(error)
              }
              return Promise.reject(error)
            })
  }
}

function errHandler(http, err, overrideGlobalHandler) {
  return e => {
    const er = {
      cannotConnect: e.code === 'ERR_NETWORK' || e.code === 'ETIMEDOUT' || undefined,
      status: e.response?.status,
      d: e.response?.data || undefined,
      e,
    }

    if (http.globalErrorHandler && (!overrideGlobalHandler || !overrideGlobalHandler(er))) {
      http.globalErrorHandler(er)
    }

    err(er)
  }
}

class RequestMethods {
  get cli() {
    return null
  }

  get(url, overrideGlobalErrorHandler = undefined) {
    return new Promise((res, err) => this.cli.get(url)
        .then(
            r => res(r.data),
            errHandler(this, err, overrideGlobalErrorHandler)
        )
    )
  }

  getBin(url, overrideGlobalErrorHandler) {
    return new Promise((res, err) => {
      this.cli.get(url, {responseType: 'arraybuffer'})
          .then(
              r => res(new Blob([r.data], {type: r.headers['content-type']})),
              errHandler(this, err, overrideGlobalErrorHandler)
          )
    })
  }

  post(url, body = undefined, overrideGlobalErrorHandler = undefined) {
    return new Promise((res, err) => this.cli.post(url, body)
        .then(
            r => res(r.data),
            errHandler(this, err, overrideGlobalErrorHandler)
        )
    )
  }

  put(url, body = undefined, overrideGlobalErrorHandler = undefined) {
    return new Promise((res, err) => this.cli.put(url, body)
        .then(
            r => res(r.data),
            errHandler(this, err, overrideGlobalErrorHandler)
        )
    )
  }

  delete(url, body = undefined, overrideGlobalErrorHandler = undefined) {
    return new Promise((res, err) => this.cli.delete(url, body)
        .then(
            r => res(r.data),
            errHandler(this, err, overrideGlobalErrorHandler)
        )
    )
  }
}

export class Http extends RequestMethods {

  constructor(baseUrl) {
    super();

    this.interceptorId = -1
    this.interceptor = null
    this.globalErrorHandler = null
    this.loginHandler = null
    this.logoutHandler = null
    this.ax = null
    this.baseUrl = baseUrl

    create(this, this.baseUrl);
  }

  get cli() {
    return this.ax
  }

  setLoginHandler(handler) {
    this.loginHandler = handler
    if (handler && this.loggedIn) {
      handler()
    }
  }

  setLogoutHandler(handler) {
    this.logoutHandler = handler
  }

  setGlobalErrorHandler(handler) {
    this.globalErrorHandler = handler
  }

  set401Interceptor(interceptor) {
    this.interceptor = interceptor
    create(this, this.baseUrl)
  }

  get loggedIn() {
    return !!localStorage.getItem(JWT_NAME)?.length
  }

  login(jwt) {
    localStorage.setItem(JWT_NAME, jwt)
    create(this, this.baseUrl)
    if (this.loginHandler) this.loginHandler()
  }

  logout() {
    localStorage.removeItem(JWT_NAME)
    create(this, this.baseUrl)
    if (this.logoutHandler) this.logoutHandler()
  }
}

export class PublicHttp extends RequestMethods {

  constructor(baseUrl) {
    super();

    this.ax = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;utf-8',
      },
      timeout: 180000
    })
  }

  get cli() {
    return this.ax
  }
}
