import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';
import pages from "../api/pages";

Vue.use(Router);
const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

export default router;
