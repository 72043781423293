<template>
  <v-dialog persistent max-width="300px" v-model="dialog">
    <v-card>
      <v-card-title>
        <v-spacer/>
        Are you sure?
        <v-spacer/>
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="confirm" color="primary" outlined dense>Confirm</v-btn>
        <v-btn :disabled="loading" @click="close" color="primary" dense>Cancel</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: Function,
  },
  computed: {
    dialog: {
      get() {
        return !!this.value;
      },
      set(val) {
        if (!val) this.$emit('input')
      }
    }
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loading = false;
    },
    confirm() {
      this.loading = true;
      this.value().finally(this.close)
    }
  }
}
</script>

<style scoped>

</style>