import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import http from "./plugins/http";
import publicHttp from "./plugins/publicHttp";
import rules from "@/api/rules";
import vueDebounce from 'vue-debounce'

import "./plugins/vuetify";
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./components/components"
import "@/scss/style.scss";

import HeroIcon from 'vue-heroicons'
import {
    transferHorizontalOutline,
    chatOutline,
    searchOutlined,
    messageConversationOutlined,
    winRarOutlined,
    dialogOutlined,
    smartphoneOutlined,
    uploadOutlined,
    userOutlined,
    signpostOutlined,
    billOutlined,
    dollarOutlined,
    penOutlined,
    printerOutlined,
    usersGroupOutlined,
    calculatorOutlined,
    addSquareOutlined,
    walletOutlined,
    settingsOutlined,
    addCircleOutlined,
    reorderOutlined,
    downloadOutlined,
    usbOutlined,
    airpodsOutlined,
    shirtOutlined,
    shortsOutlined,
    appleWatchOutlined,
    checkCircleOutlined,
    arrowLeftOutlined,
    arrowRightOutlined,
    cardOutlined,
    burgerMenuOutlined,
    closeSquareOutlined,
    arrowTwoLeftOutlined,
    coinsOutlined,
    copyOutlined,
    gpsOutlined,
    altArrowDownOutlined,
    squareTopDownOutlined,
    arrowLeftTwoOutlined,
    cloudUploadOutlined,
    homeOutlined,
    layersOutlined,
    routingOutlined,
    mapPointOutlined,
    fileTextOutlined,
    calendarOutlined,
    lockPasswordOutlined,
    trashOutlined,
    deliveryOutlined,
    arrowRightUpOutlined,
    arrowLeftDownOutlined,
    cityOutlined,
    messageOutlined,
    notebookOutlined,
    historyOutlined,

    squareTopUpBold,
    inboxOutBold,
    usersGroupTwoRoundedBold,
    penBold,
    walletBold,
    chartBold,
    passwordBold,
    trashBold,
    lockKeyholeUnlockedBold,
    widgetBold,
    qrCodeBold,
    addCircleBold,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    userBold,
    mapArrowRotateBold,
    radarBold,
    headPhonesBold,
    garageBold,
    globusBold,
    pointOnMapPerspectiveBold,
    cityBold,
    layerBold,
    threeSquaresBold,
    serverMinimalisticBold,
    crossBold,
    dangerBold,

} from './icons/icons'

HeroIcon.add([
    transferHorizontalOutline,
    chatOutline,
    searchOutlined,
    messageConversationOutlined,
    winRarOutlined,
    dialogOutlined,
    smartphoneOutlined,
    uploadOutlined,
    userOutlined,
    signpostOutlined,
    billOutlined,
    dollarOutlined,
    penOutlined,
    printerOutlined,
    usersGroupOutlined,
    calculatorOutlined,
    addSquareOutlined,
    walletOutlined,
    settingsOutlined,
    addCircleOutlined,
    reorderOutlined,
    downloadOutlined,
    usbOutlined,
    airpodsOutlined,
    shirtOutlined,
    shortsOutlined,
    appleWatchOutlined,
    checkCircleOutlined,
    arrowLeftOutlined,
    arrowRightOutlined,
    cardOutlined,
    burgerMenuOutlined,
    closeSquareOutlined,
    arrowTwoLeftOutlined,
    coinsOutlined,
    copyOutlined,
    gpsOutlined,
    altArrowDownOutlined,
    squareTopDownOutlined,
    arrowLeftTwoOutlined,
    cloudUploadOutlined,
    homeOutlined,
    layersOutlined,
    routingOutlined,
    mapPointOutlined,
    fileTextOutlined,
    calendarOutlined,
    lockPasswordOutlined,
    trashOutlined,
    deliveryOutlined,
    arrowRightUpOutlined,
    arrowLeftDownOutlined,
    cityOutlined,
    messageOutlined,
    notebookOutlined,
    historyOutlined,

    squareTopUpBold,
    inboxOutBold,
    usersGroupTwoRoundedBold,
    penBold,
    walletBold,
    chartBold,
    passwordBold,
    trashBold,
    lockKeyholeUnlockedBold,
    widgetBold,
    qrCodeBold,
    addCircleBold,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    userBold,
    mapArrowRotateBold,
    radarBold,
    headPhonesBold,
    garageBold,
    globusBold,
    pointOnMapPerspectiveBold,
    cityBold,
    layerBold,
    threeSquaresBold,
    serverMinimalisticBold,
    crossBold,
    dangerBold

])
Vue.use(HeroIcon);
Vue.use(vueDebounce);

Vue.prototype.$publicHttp = publicHttp;
Vue.prototype.$rule = rules;

const token = localStorage.getItem("token");
if (token) {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
}

Vue.config.productionTip = false;
Vue.prototype.$http = http;

window.dbg = (data) => {
    console.debug(data)
    return data;
}

window.tbl = (data) => {
    console.table(JSON.parse(JSON.stringify(data)))
    return data;
}

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
