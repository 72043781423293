<template>
  <v-app-bar class="d-flex justify-center" app flat outlined height="74">
    <v-container style="width: 1200px !important;"
                 class="d-flex justify-center align-center">
      <div class="cursor-pointer" @click="$router.push('/waiting-in-warehouse')">
        <v-img src="/static/images/logo.svg" height="74px" contain/>
      </div>
      <v-spacer/>
      <v-btn color="red" text @click="logout">logout</v-btn>
    </v-container>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AppToolbar",
  props: {
    drawer: {type: Boolean}
  },
  components: {},
  data() {
    return {
      trackCode: ""
    };
  },
  computed: {
    ...mapGetters(["windowWidth"]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions(['logout']),
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  created() {
  }
};
</script>
